import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import { Helmet } from "react-helmet"
import useLiveControl from "../controlled/useLiveControl"
import Layout from "../components/layout"
import Seo from "../components/seo"


const Feedback = () => {
  const [show, setShow] = useState(false)
  const [message] = useState(false)
  const handleClose = () => setShow(false)

  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"California Residents"}
        description={
          "Form available to allow California residents to exercise their rights in the California Privacy Act of 2018 (THE “CCPA”) as amended."
        }
      />
      <Helmet>
        <body className="do-not-sell-page html-hs-form" />
      </Helmet>

      <section className={`mb-5 ${isStaging ? "pt-3" : null }`}>
        <div className="container">
          <div className="mt-5">
            <h1 className="text-center mb-5">
              Do Not Sell or Share My Personal Information
            </h1>

            <p>
              You have the right to opt-out of the selling/ sharing of personal
              information with third parties that we do business with. The
              exercise of this right does not prohibit the sharing of information
              necessary for us to follow the law, as permitted by law, or to give
              you the best service on your accounts with us. If you have not yet
              opted out of sale/sharing of your personal information, please
              submit the form below.
            </p>

            <p>
              The information you provide below, if needed, will facilitate your
              request to opt-out of sale/sharing. If you do not provide the
              information requested below, we may not be able to identify you and
              process your request to opt-out. Any information you provide below
              will not be used, disclosed, or retained for any purpose other than
              processing the request to opt-out of sale/sharing.
            </p>

            <p>
              For more information about our privacy practices, please review our
              California Privacy Statement.
            </p>

            <p>
              If you do not have an EnerBank account or if you are not logged into
              your EnerBank account, your request to opt-out of sale/sharing will
              be linked to your browser identifier only and not linked to any
              account information because the connection between your browser and
              the account is not known to us.
            </p>
          </div>
          <div className="mt-5">
            <h2 className="text-center my-5">
              OPT-OUT Preference Signal (Global Privacy Control)
            </h2>
            <p>
              You may use an Opt-Out Preference Signal, such as the Global Privacy
              Control (GPC), to opt-out of the sale/sharing of your personal
              information.
            </p>

            <p>
              If you do not have an EnerBank account or if you are not logged into
              your EnerBank account, your request to opt-out of sale/sharing will
              be linked to your browser identifier only and not linked to any
              account information because the connection between your browser and
              the account is not known to us.
            </p>

            <p>
              If you would like us to make the connection between your browser and
              your account, and you have not yet opted out of sale/sharing your
              personal information, please submit the form below.
            </p>
          </div>
        </div>
      </section>

      <section className={`${isStaging ? "bg-light-gray" : "mb-5 p-4" }`}>
        <div className="container">
        <h2 className="text-center mb-5">I. California Residents</h2>
        <p className="mb-0 text-blue font-weight-bold">
          The form below is made available to allow California residents to
          exercise their rights in the California Privacy Act of 2018, as
          amended by the California Privacy Rights Act (THE “CCPA”).
        </p>
        <p className="small text-warning mb-5">
          Note: The right to request your information is reserved for CALIFORNIA
          RESIDENTS ONLY. You may exercise your DATA Rights by making a request
          here, or by calling <a href="tel:8009862462">1.800.986.2462</a>
        </p>

        <HubspotForm
          portalId="381510"
          formId="8f83a84d-d26c-44f5-87f7-f27c5f7bfea3"
          onSubmit={() => this.handleFormSubmit}
          onReady={form => {
            console.log("Form ready!")
          }}
          loading={<div>Loading...</div>}
        />
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default Feedback
